<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link
          class="text-decoration--none"
          :to="{ name: 'bookings', query: this.$route.query }"
          >Bookings</router-link
        >
      </v-toolbar-title>
      <v-spacer />
      <!-- <div>
        <v-btn
          color="primary"
          class="mr-4"
          medium
          text
          style="text-transform: none"
          >Cancel Booking</v-btn
        >
      </div> -->
    </v-app-bar>
    <v-row class="px-4 py-4" v-if="!loading">
      <v-col lg="8" md="6" sm="12" xs="12">
        <v-card outlined class="px-6 py-8">
          <v-card-title>Booking Information</v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="6">
                <h4>Booking Status</h4>
              </v-col>
              <v-col md="6">
                <span :class="booking.status">{{
                  booking.status.toUpperCase()
                }}</span>
              </v-col>
            </v-row>
            <v-row v-if="booking.status === 'cancelled'">
              <v-col md="6">
                <h4>Cancelled At</h4>
              </v-col>
              <v-col md="6">
                <span :class="booking.status">{{ booking.cancelledAt }}</span>
              </v-col>
            </v-row>
            <v-row v-if="booking.status === 'cancelled'">
              <v-col md="6">
                <h4>Cancellation Details</h4>
              </v-col>
              <v-col md="6">
                <span class="text-capitalize" :class="booking.status">{{
                  formatCancellationCode(booking.cancelled_reason_code)
                }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Booked Item</h4>
              </v-col>
              <v-col md="6">
                <router-link
                  :to="{
                    name: 'listing.edit',
                    params: { id: booking.listing.id },
                  }"
                  target="_blank"
                  >{{ booking.listing.title }}
                </router-link>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Booked By</h4>
              </v-col>
              <v-col md="6">
                <router-link
                  :to="{
                    name: 'user.details',
                    params: { id: booking.user.id },
                  }"
                  target="_blank"
                  >{{ booking.user.full_name }}
                </router-link>
                <span v-if="booking.user.deleted_at" class="red--text"
                  >(Deleted Account)</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Booked Dates</h4>
              </v-col>
              <v-col md="6">
                <span>{{ booking.startDate + ' - ' + booking.endDate }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Date Returned</h4>
              </v-col>
              <v-col md="6">
                <span>{{ booking.returnedDate }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-btn
                  color="error"
                  outlined
                  class="mr-4 px-6"
                  height="40px"
                  width="100%"
                  @click="showDeleteDialog = true"
                  >Delete Booking</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="4" md="6" sm="12">
        <v-card outlined class="px-6 py-8">
          <v-card-title>Payment Information</v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="6">
                <h4>Item Price Per Day</h4>
              </v-col>
              <v-col md="6" class="text-right">
                <span>{{ getMoneyFormat(booking.price) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Bond</h4>
              </v-col>
              <v-col md="6" class="text-right">
                <span>{{ getMoneyFormat(booking.bond) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Days Booked</h4>
              </v-col>
              <v-col md="6" class="text-right">
                <span>{{ booking.days }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Service Fee</h4>
              </v-col>
              <v-col md="6" class="text-right">
                <span>{{ getMoneyFormat(booking.service_fee) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Total Amount</h4>
              </v-col>
              <v-col md="6" class="text-right">
                <span>{{ getMoneyFormat(booking.total_amount) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6">
                <h4>Remaning Balance</h4>
              </v-col>
              <v-col md="6" class="text-right">
                <span>{{ getMoneyFormat(booking.remaining_balance) }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4 py-4" v-if="!loading">
      <v-col lg="8" md="6" sm="12" xs="12">
        <v-card outlined class="px-6 py-8">
          <v-card-title>Late Return Charges</v-card-title>
          <v-simple-table dense>
            <thead>
              <tr>
                <th>Date Charged</th>
                <th class="text-right">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="lateReturnPayment in lateReturnPaymentList"
                :key="lateReturnPayment.id"
              >
                <td>
                  <span> {{ formatDate(lateReturnPayment.created_at) }} </span>
                </td>
                <td class="text-right">
                  <span>{{ getMoneyFormat(lateReturnPayment.amount) }}</span>
                </td>
              </tr>

              <template v-if="loading">
                <tr v-for="index in 2" :key="index + '-skeleton'">
                  <td class="pl-0">
                    <v-skeleton-loader
                      type="list-item"
                      transition="fade-transition"
                    />
                  </td>
                  <td class="pl-0">
                    <v-skeleton-loader
                      type="list-item"
                      transition="fade-transition"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </v-simple-table>
          <div
            class="text-center mt-2 mb-8"
            v-if="lateReturnPaymentListMeta.last_page > 1"
          >
            <v-pagination
              @input="pageChanged"
              :value="lateReturnPaymentListMeta.current_page"
              :length="lateReturnPaymentListMeta.last_page"
              :total-visible="10"
              circle
            ></v-pagination>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4 py-4" v-if="!loading">
      <v-col lg="8" md="6" sm="12" xs="12" v-if="booking.reviews.length">
        <v-card>
          <v-card-title>Reviews</v-card-title>
          <v-card-text>
            <v-card
              outlined
              class="px-6 py-8 mt-5"
              v-for="(review, index) in booking.reviews"
              :key="index"
            >
              <v-card-text>
                <v-row justify="center" align="center">
                  <v-col md="6">
                    <h4>Review author</h4>
                  </v-col>
                  <v-col md="6">
                    <h4>
                      <router-link
                        :to="{
                          name: 'user.details',
                          params: { id: review.author.id },
                        }"
                        target="_blank"
                        >{{ review.author.full_name }}
                      </router-link>
                    </h4>
                  </v-col>
                  <v-col md="6">
                    <h4>Rating</h4>
                  </v-col>
                  <v-col md="6">
                    <v-rating
                      half-increments
                      v-model="review.rating"
                      background-color="orange lighten-3"
                      color="orange"
                      readonly
                    ></v-rating>
                  </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col md="6">
                    <h4>Review Title</h4>
                  </v-col>
                  <v-col md="6">
                    <p>{{ review.title }}</p>
                  </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col md="6">
                    <h4>Review Description</h4>
                  </v-col>
                  <v-col md="6">
                    <p>{{ review.description }}</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="8" md="6" sm="12" xs="12" v-else>
        <v-card outlined class="px-6 py-8">
          <v-card-title>No Reviews Yet</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="this.bondData.data.length == 0">
      <v-row class="px-4 py-4" v-if="!loading">
        <v-col lg="8" md="6" sm="12" xs="12" v-if="activeBond">
          <v-card>
            <v-card-title>Bond Details</v-card-title>
            <v-card-text>
              <v-card outlined class="px-6 py-8 mt-5">
                <v-form @submit.prevent="submitForm">
                  <v-card-text>
                    <input
                      type="hidden"
                      name="customfield"
                      class="form-control"
                      :value="booking.id"
                    />
                    <v-row justify="center" align="center">
                      <v-col md="6">
                        <h4>Payment Id</h4>
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          hide-details="auto"
                          v-model="activeBond.payment_id"
                          :value="activeBond.payment_id"
                          disabled
                        ></v-text-field>
                        <!-- <v-select
                          label="Select Payment Id"
                          :items="[activeBond.payment_id]"
                          v-model="formData.payment_id"
                          variant="outlined"
                          required
                        ></v-select>  -->
                      </v-col>
                      <v-col md="6">
                        <h4>Enter Reason</h4>
                      </v-col>
                      <v-col md="6">
                        <v-textarea
                          clearable
                          label="Reason"
                          rows="2"
                          cols="2"
                          v-model="formData.reason"
                          required
                          :rules="[minLengthRule]"
                        ></v-textarea>
                      </v-col>
                      <v-col md="6">
                        <h4>Bond Amount</h4>
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          hide-details="auto"
                          :value="'$' + booking.bond"
                          disabled
                        ></v-text-field>
                      </v-col>

                      <v-col md="6">
                        <h4>Deduct Amount</h4>
                      </v-col>
                      <v-col md="6">
                        <v-text-field
                          hide-details="auto"
                          label="Enter Amount"
                          v-model="formData.deduct_amount"
                          @keypress="isNumber($event)"
                          @copy.prevent
                          @paste.prevent
                          :disabled="isDisabled"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col md="6">
                        <h4>Release Amount</h4>
                      </v-col>
                      <v-col md="6">
                        <span class="disable_txt mt-3">
                          $
                          {{
                            this.valueData === true
                              ? booking.bond - formData.deduct_amount
                              : 0
                          }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row justify="center" align="center">
                      <v-col md="6"></v-col>
                      <v-col md="6">
                        <v-btn
                          color="info"
                          class="mr-4 px-6"
                          height="40px"
                          width="50%"
                          :disabled="isDisabled"
                          @click="showBondDialog = true"
                        >
                          Submit
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col lg="8" md="6" sm="12" xs="12" v-else>
          <v-card outlined class="px-6 py-8">
            <v-card-title>No Bond Request Yet</v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-col lg="8" md="6" sm="12" xs="12">
        <v-card>
          <v-card-title>Bond Report</v-card-title>
          <v-card-text>
            <v-card
              outlined
              class="px-6 py-8 mt-5"
              v-for="(bond, index) in this.bondData.data"
              :key="index"
            >
              <v-card-text>
                <v-row justify="center" align="center">
                  <v-col md="6">
                    <h4>Payment Id</h4>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      v-model="bond.bond_payment_id"
                      :value="bond.bond_payment_id"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row justify="center" align="center">
                  <v-col md="6">
                    <h4>Bond Amount</h4>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      :value="
                        bond.bond_amount == 0
                          ? bond.bond_amount
                          : '$' + bond.bond_amount
                      "
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col md="6">
                    <h4>Deduction Amount</h4>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      :value="
                        bond.deduction_amount == 0
                          ? bond.deduction_amount
                          : '$' + bond.deduction_amount
                      "
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col md="6">
                    <h4>Release Amount</h4>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      :value="
                        bond.release_amount == 0
                          ? bond.release_amount
                          : '$' + bond.release_amount
                      "
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center" align="center">
                  <v-col md="6">
                    <h4>Reason</h4>
                  </v-col>
                  <v-col md="6">
                    {{ bond.reason }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </div>

    <v-overlay absolute :value="loading" opacity="0.2">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <confirm-modal
      v-model="showDeleteDialog"
      title="Delete Listing"
      message="Are you sure you want to delete this booking?"
      @cancel="showDeleteDialog = false"
      @confirm="deleteBooking"
      confirm-color="error"
    />
    <confirm-modal
      v-model="showBondDialog"
      title="Bond"
      message="Are you sure you want to deduct from the bond amount?"
      @cancel="showBondDialog = false"
      @confirm="submitFormData"
      confirm-color="success"
    />
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <v-btn text @click="snackbar.show = false">OK</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ConfirmModal from '@/components/modals/ConfirmModal'
import Currency from '@/utils/mixins/Currency'
import dayjs from 'dayjs'
import { get } from 'lodash'
import Snackbar from '@/utils/mixins/Snackbar'

export default {
  name: 'BookingDetailsPage',
  mixins: [Currency, Snackbar],
  data() {
    return {
      loading: false,
      loadingBtn: false,
      showDeleteDialog: false,
      showBondDialog: false,
      deleting: false,
      valueData: false,
      icons: {
        arrowLeft: mdiChevronLeft,
      },
      formData: {
        booking_id: '',
        payment_id: '',
        reason: '',
        deduct_amount: '',
      },
      minLengthRule: (v) =>
        (v && v.length >= 5) || 'Reason must be at least 5 characters long',
    }
  },
  components: {
    AppBarNavIcon,
    ConfirmModal,
  },

  computed: {
    ...mapState({
      booking: (state) => state.booking.recordDetails,
      bondData: (state) => state.booking.bondData,
      lateReturnPaymentListMeta: (state) =>
        state.booking.lateReturnPaymentListMeta,
      lateReturnPaymentList: (state) => state.booking.lateReturnPaymentList,
    }),
    isDisabled() {
      const currentDate = new Date()
      const disableUntilDate = this.booking
      // if (disableUntilDate.end_date) {
      //   // Get today's date without time
      //   const todayDate = new Date(
      //     currentDate.getFullYear(),
      //     currentDate.getMonth(),
      //     currentDate.getDate()
      //   )

      //   // Parse the end date from the end_date string in 'YYYY-MM-DD' format
      //   const endDateParts = disableUntilDate.end_date.split('-')
      //   const endDate = new Date(
      //     endDateParts[0], // Year
      //     endDateParts[1] - 1, // Month (0-based index)
      //     endDateParts[2] // Day
      //   )

      //   // Return true if todayDate is the same as or before endDate, false otherwise
      //   console.log(todayDate <= endDate, todayDate, endDate)
      //   return todayDate <= endDate
      // }
      if (disableUntilDate.end_date) {
        // Get today's date without time
        const todayDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        )

        // Parse the end date from the end_date string in 'YYYY-MM-DD' format
        const endDateParts = disableUntilDate.end_date.split('-')
        const endDate = new Date(
          endDateParts[0], // Year
          endDateParts[1] - 1, // Month (0-based index)
          endDateParts[2] // Day
        )

        // Return true if todayDate is the same as or before endDate, false otherwise
        // console.log(todayDate >= endDate, todayDate, endDate)
        return todayDate <= endDate
      }
      return false
    },
    activeBond() {
      return this.booking.bond_payment_details.find(
        (bond) => bond.cancelled_at === null
      )
    },
  },

  methods: {
    ...mapActions({
      getBookingDetails: 'booking/getRecordDetails',
      getBookingLateReturnPayments: 'booking/getBookingLateReturnPayments',
      bondDeduction: 'booking/bondDeduction',
      bondListing: 'booking/bondListing',
    }),

    ...mapMutations({
      clearBookingDetails: 'booking/clearRecordDetails',
    }),

    async getBooking() {
      this.loading = true
      await this.getBookingDetails(this.$route.params.id)
      this.loading = false
    },

    async bondList(id) {
      await this.bondListing({ bookingId: id })
    },

    async getBookingLateReturnCharges(page = 1) {
      let params = { page }
      params.booking_id = this.$route.params.id
      await this.getBookingLateReturnPayments(params)
    },

    formatCancellationCode(cancellationCode) {
      return cancellationCode.replace(/_/g, ' ')
    },

    formatDate(date) {
      return dayjs.utc(date).local().format('MMM DD, YYYY')
    },

    pageChanged(page) {
      this.getBookingLateReturnCharges(page)
    },

    async deleteBooking() {
      this.deleting = true

      this.booking
        .delete()
        .then(() => {
          this.showMainSnackbar('Booking deleted successfully!', 'success')
          this.$router.replace('/bookings')
        })
        .catch((e) => {
          this.showSnackbar(get(e, 'response.data.message'), 'error')
        })
        .finally(() => {
          this.deleting = false
          this.showDeleteDialog = false
        })
    },

    async submitFormData() {
      this.loading = true
      // Validate form data
      if (
        !this.formData.reason ||
        !this.formData.deduct_amount ||
        this.formData.reason.length < 5
      ) {
        this.showBondDialog = false
        this.loading = false

        if (!this.formData.reason) {
          this.showMainSnackbar('Reason cannot be empty', 'error')
        } else if (!this.formData.deduct_amount) {
          this.showMainSnackbar('Deduct amount cannot be empty', 'error')
        } else if (this.formData.reason.length < 5) {
          this.showMainSnackbar(
            'Reason must be at least 5 characters long',
            'error'
          )
        }

        return
      }

      const paymentIDs = this.booking.bond_payment_details
        .filter((data) => data.cancelled_at === null)
        .map((data) => data.payment_id)
      this.showBondDialog = false
      const submittedData = {
        bookingId: this.booking.id,
        paymentId: paymentIDs[0],
        reason: this.formData.reason,
        bondAmount: this.formData.deduct_amount,
      }
      const message = await this.bondDeduction(submittedData)

      this.showMainSnackbar(message, 'success')
      // alert(message)
      this.loading = false
      // const bondData = document.getElementById('bond_Data')
      // const bondReport = document.getElementById('bond_Report')
      // if (message) {
      // bondData.style.display = 'none'
      // bondReport.style.display = 'block'
      /// }
      // setTimeout(() => {
      // this.bondData()
      window.location.reload()
      //   this.loading = false
      //   this.getBooking()
      // }, 2000)
    },

    isNumber(evt) {
      this.valueData = true
      const allowedValue = parseFloat(this.booking.bond)
      const keysAllowed = [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '.',
      ]
      const keyPressed = evt.key

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
        return
      }

      const inputElement = evt.target
      const currentValue = inputElement.value
      const newValue = currentValue + keyPressed

      if (parseFloat(newValue) > allowedValue || parseFloat(newValue) < 1) {
        evt.preventDefault()
        this.showMainSnackbar(
          `Your current bond value is ${this.booking.bond}. You can enter a value between 1 and ${this.booking.bond}.`,
          'error'
        )
      }
    },

    showError(message) {
      alert(message)
    },
  },

  created() {
    this.getBooking()
    this.bondList(this.$route.params.id)
    this.getBookingLateReturnCharges(1)
  },

  destroyed() {
    this.clearBookingDetails()
  },

  watch: {
    $route() {
      this.getBooking()
    },
  },
}
</script>

<style Scoped>
table > tbody > tr:hover td {
  background: #eeeeee !important;
  cursor: pointer;
}

.completed {
  color: #1b5e20;
}
.current {
  color: #01579b;
}
.upcoming {
  color: #00897b;
}
.cancelled {
  color: #d50000;
}
.disable_txt {
  width: 100%;
  display: block;
  border-bottom: 1px dotted gray;
  color: rgba(0, 0, 0, 0.38);
  font-size: 1rem;
}
</style>
